<template>
	<app tabbar>
		<div class="user-info flex-column flex-center">
			<div class="icon"><img src="@/assets/image/user_icon.png"></div>
			<p class="name">{{userInfo.user_realname}}</p>
			<p class="company">{{userInfo.name}}</p>
		</div>
		
		<ul class="menu-list">
			<router-link to="/message_list">
				<li class="flex-center">
					<i class="icon tf tf-menu-message"></i>
					<p class="flex-grow">消息中心</p>
					<ins v-if="messageTotal > 0">{{messageTotal}}</ins>
					<i class="angle tf tf-angle-right"></i>
				</li>
			</router-link>
			<a @click="openFile('https://api.szbhjg.cn/upload/业主端操作手册.pdf')">
				<li class="flex-center">
					<i class="icon tf tf-menu-manual"></i>
					<p class="flex-grow">操作手册</p>
					<i class="angle tf tf-angle-right"></i>
				</li>
			</a>
		</ul>
	</app>
</template>

<script>
	import {mapState} from 'vuex'
	
	export default {
		computed: {
			...mapState(['userInfo', 'messageTotal'])
		},
		
		mounted() {
			this.$store.dispatch('updateMessageTotal');
		}
	};
</script>

<style lang="stylus" scoped>
	.user-info {
		height: 2.6rem;
		color: #fff;
		background: #3296FA;
		
		.icon {
			width: 1rem;
			height: 1rem;
			border-radius: 100%;
			overflow: hidden;
		}
		
		.name {
			margin: .26rem 0 .2rem;
			font-size: .32rem;
			font-weight: bold;
		}
		
		.company {
			font-size: .26rem;
		}
	}
	
	.menu-list {
		border-radius: .12rem;
		padding: .24rem .28rem;
		margin: .32rem;
		background: #fff;
		
		a:nth-child(n+2) li {
			border-top: 1px solid #E9E9E9;
		}
		
		li {
			height: 1rem;
			
			.icon {
				width: .6rem;
				font-size: .32rem;
			}
			
			p {
				font-size: .32rem;
				font-weight: bold;
			}
				
			ins {
				box-sizing: border-box;
				min-width: .32rem;
				height: .32rem;
				border-radius: .16rem;
				padding: 0 .1rem;
				margin-right: .2rem;
				line-height: .34rem;
				font-size: .24rem;
				text-align: center;
				color: #fff;
				background: #E63633;
			}
			
			.angle {
				color: #A5A5A5;
			}
		}
	}
</style>